import { default as _91_46_46_46cms_93VogYrbackQMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93Uo43hkulTXMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/3d-projects/[...slug].vue?macro=true";
import { default as indexa0o6BXaafCMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/3d-projects/index.vue?macro=true";
import { default as _91_46_46_46slug_93gHyQoskhVwMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/akcii/[...slug].vue?macro=true";
import { default as indexYCk7tNRPByMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/akcii/index.vue?macro=true";
import { default as _91_46_46_46slug_93INEIwNSnzgMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/articles/[...slug].vue?macro=true";
import { default as indexMFoLINT210Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/articles/index.vue?macro=true";
import { default as indexnU8XK1iS3OMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/authorization/index.vue?macro=true";
import { default as index236EY88MIBMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/cart/index.vue?macro=true";
import { default as indexIqTX7CkW3sMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93EekxrXjpZCMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/catalog/[...slug].vue?macro=true";
import { default as indexf2l2jMETkBMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_93jBpMWhyZ6iMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/catalogsearch/[...slug].vue?macro=true";
import { default as resultRiTsg1IctjMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/catalogsearch/result.vue?macro=true";
import { default as indexpHXANJ2IDvMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/clear-page-slider/index.vue?macro=true";
import { default as _91_46_46_46slug_93oItMaSYdU9Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/collection/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93VOPHv5Eur0Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/fabriki/[...slug].vue?macro=true";
import { default as indexPbcbUjI5JcMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/fabriki/index.vue?macro=true";
import { default as _91_46_46_46slug_93jKpRudDn8dMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/product/[...slug].vue?macro=true";
import { default as indexjYCbUElwb5Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/profile/index.vue?macro=true";
import { default as indexOuIGhYDt2lMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/profile/orders/index.vue?macro=true";
import { default as indexmD9GMZ8mhQMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/recently/index.vue?macro=true";
import { default as _91_46_46_46slug_93gkTenhwGwxMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/sets/[...slug].vue?macro=true";
import { default as indexNwC3DUFG4uMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93VogYrbackQMeta?.name ?? "cms",
    path: _91_46_46_46cms_93VogYrbackQMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93VogYrbackQMeta || {},
    alias: _91_46_46_46cms_93VogYrbackQMeta?.alias || [],
    redirect: _91_46_46_46cms_93VogYrbackQMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Uo43hkulTXMeta?.name ?? "3d-projects-slug",
    path: _91_46_46_46slug_93Uo43hkulTXMeta?.path ?? "/3d-projects/:slug(.*)*",
    meta: _91_46_46_46slug_93Uo43hkulTXMeta || {},
    alias: _91_46_46_46slug_93Uo43hkulTXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Uo43hkulTXMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/3d-projects/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexa0o6BXaafCMeta?.name ?? "3d-projects",
    path: indexa0o6BXaafCMeta?.path ?? "/3d-projects",
    meta: indexa0o6BXaafCMeta || {},
    alias: indexa0o6BXaafCMeta?.alias || [],
    redirect: indexa0o6BXaafCMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/3d-projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93gHyQoskhVwMeta?.name ?? "akcii-slug",
    path: _91_46_46_46slug_93gHyQoskhVwMeta?.path ?? "/akcii/:slug(.*)*",
    meta: _91_46_46_46slug_93gHyQoskhVwMeta || {},
    alias: _91_46_46_46slug_93gHyQoskhVwMeta?.alias || [],
    redirect: _91_46_46_46slug_93gHyQoskhVwMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/akcii/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexYCk7tNRPByMeta?.name ?? "akcii",
    path: indexYCk7tNRPByMeta?.path ?? "/akcii",
    meta: indexYCk7tNRPByMeta || {},
    alias: indexYCk7tNRPByMeta?.alias || [],
    redirect: indexYCk7tNRPByMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/akcii/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93INEIwNSnzgMeta?.name ?? "articles-slug",
    path: _91_46_46_46slug_93INEIwNSnzgMeta?.path ?? "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93INEIwNSnzgMeta || {},
    alias: _91_46_46_46slug_93INEIwNSnzgMeta?.alias || [],
    redirect: _91_46_46_46slug_93INEIwNSnzgMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexMFoLINT210Meta?.name ?? "articles",
    path: indexMFoLINT210Meta?.path ?? "/articles",
    meta: indexMFoLINT210Meta || {},
    alias: indexMFoLINT210Meta?.alias || [],
    redirect: indexMFoLINT210Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexnU8XK1iS3OMeta?.name ?? "authorization",
    path: indexnU8XK1iS3OMeta?.path ?? "/authorization",
    meta: indexnU8XK1iS3OMeta || {},
    alias: indexnU8XK1iS3OMeta?.alias || [],
    redirect: indexnU8XK1iS3OMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: index236EY88MIBMeta?.name ?? "cart",
    path: index236EY88MIBMeta?.path ?? "/cart",
    meta: index236EY88MIBMeta || {},
    alias: index236EY88MIBMeta?.alias || [],
    redirect: index236EY88MIBMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexIqTX7CkW3sMeta?.name ?? "cart-success",
    path: indexIqTX7CkW3sMeta?.path ?? "/cart/success",
    meta: indexIqTX7CkW3sMeta || {},
    alias: indexIqTX7CkW3sMeta?.alias || [],
    redirect: indexIqTX7CkW3sMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93EekxrXjpZCMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93EekxrXjpZCMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93EekxrXjpZCMeta || {},
    alias: _91_46_46_46slug_93EekxrXjpZCMeta?.alias || [],
    redirect: _91_46_46_46slug_93EekxrXjpZCMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexf2l2jMETkBMeta?.name ?? "catalog",
    path: indexf2l2jMETkBMeta?.path ?? "/catalog",
    meta: indexf2l2jMETkBMeta || {},
    alias: indexf2l2jMETkBMeta?.alias || [],
    redirect: indexf2l2jMETkBMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jBpMWhyZ6iMeta?.name ?? "catalogsearch-slug",
    path: _91_46_46_46slug_93jBpMWhyZ6iMeta?.path ?? "/catalogsearch/:slug(.*)*",
    meta: _91_46_46_46slug_93jBpMWhyZ6iMeta || {},
    alias: _91_46_46_46slug_93jBpMWhyZ6iMeta?.alias || [],
    redirect: _91_46_46_46slug_93jBpMWhyZ6iMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/catalogsearch/[...slug].vue").then(m => m.default || m)
  },
  {
    name: resultRiTsg1IctjMeta?.name ?? "catalogsearch-result",
    path: resultRiTsg1IctjMeta?.path ?? "/catalogsearch/result",
    meta: resultRiTsg1IctjMeta || {},
    alias: resultRiTsg1IctjMeta?.alias || [],
    redirect: resultRiTsg1IctjMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/catalogsearch/result.vue").then(m => m.default || m)
  },
  {
    name: indexpHXANJ2IDvMeta?.name ?? "clear-page-slider",
    path: indexpHXANJ2IDvMeta?.path ?? "/clear-page-slider",
    meta: indexpHXANJ2IDvMeta || {},
    alias: indexpHXANJ2IDvMeta?.alias || [],
    redirect: indexpHXANJ2IDvMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/clear-page-slider/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93oItMaSYdU9Meta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93oItMaSYdU9Meta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93oItMaSYdU9Meta || {},
    alias: _91_46_46_46slug_93oItMaSYdU9Meta?.alias || [],
    redirect: _91_46_46_46slug_93oItMaSYdU9Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VOPHv5Eur0Meta?.name ?? "fabriki-slug",
    path: _91_46_46_46slug_93VOPHv5Eur0Meta?.path ?? "/fabriki/:slug(.*)*",
    meta: _91_46_46_46slug_93VOPHv5Eur0Meta || {},
    alias: _91_46_46_46slug_93VOPHv5Eur0Meta?.alias || [],
    redirect: _91_46_46_46slug_93VOPHv5Eur0Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/fabriki/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexPbcbUjI5JcMeta?.name ?? "fabriki",
    path: indexPbcbUjI5JcMeta?.path ?? "/fabriki",
    meta: indexPbcbUjI5JcMeta || {},
    alias: indexPbcbUjI5JcMeta?.alias || [],
    redirect: indexPbcbUjI5JcMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/fabriki/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jKpRudDn8dMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93jKpRudDn8dMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93jKpRudDn8dMeta || {},
    alias: _91_46_46_46slug_93jKpRudDn8dMeta?.alias || [],
    redirect: _91_46_46_46slug_93jKpRudDn8dMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexjYCbUElwb5Meta?.name ?? "profile",
    path: indexjYCbUElwb5Meta?.path ?? "/profile",
    meta: indexjYCbUElwb5Meta || {},
    alias: indexjYCbUElwb5Meta?.alias || [],
    redirect: indexjYCbUElwb5Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexOuIGhYDt2lMeta?.name ?? "profile-orders",
    path: indexOuIGhYDt2lMeta?.path ?? "/profile/orders",
    meta: indexOuIGhYDt2lMeta || {},
    alias: indexOuIGhYDt2lMeta?.alias || [],
    redirect: indexOuIGhYDt2lMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/profile/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexmD9GMZ8mhQMeta?.name ?? "recently",
    path: indexmD9GMZ8mhQMeta?.path ?? "/recently",
    meta: indexmD9GMZ8mhQMeta || {},
    alias: indexmD9GMZ8mhQMeta?.alias || [],
    redirect: indexmD9GMZ8mhQMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/recently/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93gkTenhwGwxMeta?.name ?? "sets-slug",
    path: _91_46_46_46slug_93gkTenhwGwxMeta?.path ?? "/sets/:slug(.*)*",
    meta: _91_46_46_46slug_93gkTenhwGwxMeta || {},
    alias: _91_46_46_46slug_93gkTenhwGwxMeta?.alias || [],
    redirect: _91_46_46_46slug_93gkTenhwGwxMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/sets/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexNwC3DUFG4uMeta?.name ?? "wishlist",
    path: indexNwC3DUFG4uMeta?.path ?? "/wishlist",
    meta: indexNwC3DUFG4uMeta || {},
    alias: indexNwC3DUFG4uMeta?.alias || [],
    redirect: indexNwC3DUFG4uMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/pages/wishlist/index.vue").then(m => m.default || m)
  }
]